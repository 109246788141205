<template>
  <div class="rankTable">
    <v-data-table 
    v-model:sort-by="sortBy"
    v-model:items-per-page="itemsPerPage"
    :headers="labels"
    :items="table"
    item-value="name"
    class="elevation-1">
    <template v-slot:[`item.img`]="{ item }">
      <img :src="item.props.title.img" style="width: 30px;">
    </template>
    </v-data-table>
  </div>
</template>

<script>
/* eslint-disable */
// import { defineComponent } from 'vue';

export default {
  data(){
    return {
      itemsPerPage:20,
      sortBy:[{ key: '順位', order: 'asc' }],
    }
  },
  name: 'RankTable',
  props: ['table', 'labels'],
  created() {
    console.log(this.table)
  }
};
</script>