<template>
  <div class="app">
    <v-toolbar :elevation="8" bg-color="primary">
      <v-toolbar-title>
        J-League</v-toolbar-title>
    </v-toolbar>

    <v-card>
      <v-tabs bg-color="primary" v-model="tab">
        <v-tab value="home">Home</v-tab>
        <v-tab value="rank">Ranking</v-tab>
        <v-tab value="statsGraph">Bar Chart</v-tab>
        <v-tab value="vsData">Scatter Chart</v-tab>
        <v-tab value="vsTeams">Rader Chart</v-tab>
      </v-tabs>

      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="home">
            <v-container>
              <h3>●本サイトについて</h3>
              <a href="https://www.football-lab.jp/">Football Lab</a>のデータから、J1各チームのスタッツをグラフにして確認することができます。
              <v-list lines="two">
              <v-list-item
                title="Ranking"
                subtitle="確認したいシーズンの順位表が確認できます。"
              ></v-list-item>
              <v-list-item
                title="Bar Chart"
                subtitle="シーズンと確認したいスタッツを選択して、そのシーズン中の全チームのスタッツを確認できます。"
              ></v-list-item>
              <v-list-item
                title="Scatter Chart"
                subtitle="シーズンと確認したいスタッツを2つ選択して、スタッツの相関関係を確認できます"
              ></v-list-item>
              <v-list-item
                title="Rader Chart"
                subtitle="シーズンとチームを2つ選択して、チーム同士の比較ができます。"
              ></v-list-item>
            </v-list>
              ※データは1試合平均のデータを使用しています。<br>
              ※本サイトの取扱データは2020シーズン以降です。
            </v-container>
          </v-window-item>

          <v-window-item value="rank">
            <v-container>
              <v-sheet class="ma-2 pa-2">
                <v-row>
                  <v-select label="Season" v-model="rank.year" :items="years"></v-select>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col>
                    <v-btn block rounded="xl" size="x-large" density="compact" @click="getRankData(rank.year)">Show
                      ranking</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet>
                <RankTable :labels="rank.tableLabels" :table="rank.rankTable" />
              </v-sheet>
            </v-container>
          </v-window-item>

          <v-window-item value="statsGraph">
            <v-container>
              <v-sheet class="ma-2 pa-2">
                <v-row>
                  <v-select label="Season" v-model="bar.year" :items="years"></v-select>
                </v-row>
                <v-row><v-select label="Stats" v-model="bar.selectStats" :items="stats"></v-select></v-row>
                <v-row justify="center" align="center">
                  <v-col>
                    <v-btn block rounded="xl" size="x-large" density="compact"
                      @click="getBarStats(bar.year, bar.selectStats)">Show
                      graph</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet class="ma-2 pa-2">
                <BarChartTest v-if="bar.isDisplay" :chartData="bar.barData" :options="bar.options" />
              </v-sheet>
            </v-container>
          </v-window-item>

          <v-window-item value="vsData">
            <v-container>
              <v-sheet class="ma-2 pa-2">
                <v-row>
                  <v-select label="Season" v-model="scatter.year" :items="years"></v-select>
                </v-row>
                <v-row>
                  <v-col col="6"><v-select label="Stats1" v-model="scatter.selectStats1"
                      :items="stats"></v-select></v-col>
                  <v-col col="6"><v-select label="Stats2" v-model="scatter.selectStats2"
                      :items="stats"></v-select></v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col>
                    <v-btn block rounded="xl" size="x-large" density="compact"
                      @click="getScatterStats(scatter.year, scatter.selectStats1, scatter.selectStats2)">Show
                      graph</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet class="ma-2 pa-2">
                <ScatterChartTest v-if="scatter.isDisplay" :chartData="scatter.scatterData"
                  :options="scatter.scatterOptions" />
              </v-sheet>
            </v-container>
          </v-window-item>

          <v-window-item value="vsTeams">
            <v-container>
              <v-sheet class="ma-2 pa-2">
                <v-row>
                  <v-col col="6"><v-select label="Season1" v-model="rader.year1" :items="years"
                      @update:modelValue="getRaderTeams1"></v-select></v-col>
                  <v-col col="6"><v-select label="Team1" v-model="rader.selectTeam1"
                      :items="rader.teams1"></v-select></v-col>
                </v-row>
                <v-row>
                  <v-col col="6"><v-select label="Season2" v-model="rader.year2" :items="years"
                      @update:modelValue="getRaderTeams2"></v-select></v-col>
                  <v-col col="6"><v-select label="Team2" v-model="rader.selectTeam2"
                      :items="rader.teams2"></v-select></v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col>
                    <v-btn block rounded="xl" size="x-large" density="compact"
                      @click="getRaderChart(rader.year1, rader.selectTeam1, rader.year2, rader.selectTeam2)">Show
                      graph</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>

              <v-sheet class="ma-2 pa-2">
                <RaderChart v-if="rader.isDisplay" :chartData="rader.raderData" :options="rader.raderOptions" />
              </v-sheet>
            </v-container>
          </v-window-item>

        </v-window>
      </v-card-text>
    </v-card>
    ※本サイトは<a href="https://www.football-lab.jp/">Football Lab</a>のデータを参照しています。
  </div>
</template>

<script>
/* eslint-disable */
import RaderChart from './components/RaderChart.vue';
import BarChartTest from './components/BarChart.vue';
import ScatterChartTest from './components/ScatterChart.vue';
import RankTable from './components/RankTable.vue';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getDocs, getDoc, doc, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDsWfkT2qyahZRXlKtz_LYrhcK4RUnoxWs",
  authDomain: "dev-app-mu.firebaseapp.com",
  projectId: "dev-app-mu",
  storageBucket: "dev-app-mu.appspot.com",
  messagingSenderId: "944110297182",
  appId: "1:944110297182:web:366a43e88c7e20db7954cf"
};

const DEFAULT_MIN = 1000;
const DEFAULT_MAX = 0;
const MIN_RATIO = 0.7;
const MAX_RATIO = 1.3;
const LIMIT_WINDOW_SIZE = 800;
const IMAGE_SIZE = 40;
const SMALL_IMAGE_SIZE = 30;
const DEFAULT_STATS = "ゴール";
const MAX_TEAM_NUMBER = 20;



// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore();
const labels = [
  'ゴール',
  'シュート',
  'パス',
  'ドリブル',
  'クロス',
  'ボール保持率',
  'ペナルティエリア進入回数',
  '攻撃回数',
  '30mライン進入回数',
  'クリア',
  'タックル',
  'インターセプト'
];
const tableLabels = [
  { title: '順位', key: '順位', align: 'start', width: "100px" },
  { title: '', key: 'img', align: 'start', width: "50px" },
  { title: 'クラブ名', key: 'name', align: 'start', width: "200px" },
  { title: '勝点', key: '勝点', align: 'start', width: "100px" },
  { title: '得失点差', key: '得失点差', align: 'start', width: "120px" },
  { title: '得点', key: '得点', align: 'start', width: "100px" },
  { title: '失点', key: '失点', align: 'start', width: "100px" }
];

export default {
  name: "App",
  components: {
    BarChartTest, ScatterChartTest, RaderChart, RankTable
  },
  data() {
    return {
      tab: null,
      years: [],
      graph: ['Bar', 'Scatter', 'Rader'],
      rank: {
        year: "",
        teams: [],
        rankTable: [],
        tableLabels: tableLabels,
      },
      stats: labels,
      statsCollection: "",
      bar: {
        isDisplay: false,
        year: "",
        teams: [],
        selectStats: "",
        barData: {
          labels: [],
          datasets: [
            {
              barPercentage: 0.9,
              axis: 'y',
              backgroundColor: '#9BD0F5',
            },
          ],
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: true,
        }
      },
      scatter: {
        isDisplay: false,
        year: "",
        teams: [],
        selectStats1: "",
        selectStats2: "",
        scatterData: {
          datasets: [],
        },
        scatterOptions: {
          plugins: {
            legend: {
              display: false,
            }
          },
          scales: {
            x: { title: { display: true, text: "" }, suggestedMin: 0, suggestedMax: 100 },
            y: { title: { display: true, text: "" }, suggestedMin: 0, suggestedMax: 100 }
          }
        },
      },
      rader: {
        isDisplay: false,
        year1: "",
        year2: "",
        teams1: [],
        teams2: [],
        selectTeam1: "",
        selectTeam2: "",
        raderData: {
          labels: labels,
          datasets: [{
            label: '',
            data: [],
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
          },
          {
            label: '',
            data: [],
            fill: true,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)'
          }]
        },
        raderOptions: {
          scales: {
            r: {
              ticks: {
                display: false
              }
            }
          }
        }
      },
    }
  },
  async created() {
    this.getYears();
    const now = new Date();
    const year = String(now.getFullYear());
    this.rank.year = year;
    this.bar.year = year;
    this.bar.stats = DEFAULT_STATS;
    this.scatter.year = year;
    this.rader.year1 = year;
    this.rader.year2 = year;
    this.getRankData(this.rank.year);
    this.getRaderTeams1(this.rader.year1, this.rader.year1);
    this.getRaderTeams2(this.rader.year2);
  },
  methods: {
    async getYears() {
      const docRef = doc(db, "const", "years");
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      data["years"].forEach((y) => {
        this.years.push(y);
      })
    },
    // シーズンに対応するチームを取得する 
    async getTeams(event) {
      const querySnapshot = await getDocs(collection(db, event));
      this.statsCollection = querySnapshot;
      let teams = [];
      this.statsCollection.forEach((doc) => {
        teams.push(doc.id);
      });
      return teams;
    },
    async getRaderTeams1(event) {
      this.rader.selectTeam1 = "";
      this.rader.teams1 = await this.getTeams(event);
    },
    async getRaderTeams2(event) {
      this.rader.selectTeam2 = "";
      this.rader.teams2 = await this.getTeams(event)
    },
    // 選択したシーズンの順位表を表示する
    async getRankData(year) {
      this.rank.teams = await this.getTeams(year);
      this.rank.rankTable = [];
      this.rank.teams.forEach(async (team) => {
        const docRef = doc(db, year, team);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        let tableData = {}
        tableData['name'] = team
        tableLabels.forEach((l) => {
          if (l.key != "name") {
            tableData[l.key] = data[l.key];
          }
        })
        this.rank.rankTable.push(tableData);
      });
    },
    // 選択したスタッツをBarグラフで表示する処理
    async getBarStats(year, selectStats) {
      this.getBarChartOption();
      this.bar.barData.datasets[0].data = [];
      this.bar.barData.labels = [];
      let index = this.stats.indexOf(selectStats)
      this.bar.barData.datasets[0].label = this.stats[index];
      this.bar.isDisplay = true;
      let dataSet = [];
      this.bar.teams = await this.getTeams(year);
      this.bar.teams.forEach(async (team) => {
        const docRef = doc(db, year, team);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        dataSet.push({ "team": team, "data": data[this.stats[index]] })
        dataSet.sort((a, b) => b.data - a.data)
        this.bar.barData.datasets[0].data.splice(0);
        this.bar.barData.labels.splice(0);
        dataSet.forEach((data) => {
          this.bar.barData.datasets[0].data.push(data.data);
          this.bar.barData.labels.push(data.team);
        })
      })

    },
    // WindowのサイズからBarChartの設定を変更する処理
    getBarChartOption() {
      const windowSize = window.innerWidth;
      if (windowSize < LIMIT_WINDOW_SIZE) {
        // this.bar.options.responsive = false;
        this.bar.options.maintainAspectRatio = false;
      } else {
        // this.bar.options.responsive = true;
        this.bar.options.maintainAspectRatio = true;
      }
    },
    // Windowのサイズからアイコンのサイズを取得する処理
    getImageSize() {
      const windowSize = window.innerWidth;
      return windowSize < LIMIT_WINDOW_SIZE ? SMALL_IMAGE_SIZE : IMAGE_SIZE;
    },
    getMinAxis(a, b) {
      console.log(Math.min(a, b) * MIN_RATIO)
      return Math.min(a, b) * MIN_RATIO;
    },
    getMaxAxis(a, b) {
      return Math.max(a, b) * MAX_RATIO;
    },
    // 引数のスタッツ1とスタッツ2で散布図用のデータを取得する処理
    async getScatterStats(year, stats1, stats2) {
      this.scatter.scatterOptions.scales.x.title.text = stats1
      this.scatter.scatterOptions.scales.y.title.text = stats2
      this.scatter.scatterData.label = stats1 + " vs " + stats2;
      let index1 = this.stats.indexOf(stats1);
      let index2 = this.stats.indexOf(stats2);
      if (index1 < 0 || index2 < 0) {
        return false
      }
      this.scatter.teams = await this.getTeams(year)
      await this.getScatterData(this.scatter.teams, year, index1, index2);
      this.scatter.isDisplay = true;
    },
    async getScatterData(teams, year, index1, index2) {
      this.scatter.scatterData.datasets = [];
      let xMin = DEFAULT_MIN;
      let xMax = DEFAULT_MAX;
      let yMin = DEFAULT_MIN;
      let yMax = DEFAULT_MAX;
      let imageSize = this.getImageSize();
      teams.forEach(async (team, i) => {
        const docRef = doc(db, year, team);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        let xData = data[this.stats[index1]]
        let yData = data[this.stats[index2]]
        this.scatter.scatterOptions.scales.x.suggestedMin = this.getMinAxis(xMin, xData);
        this.scatter.scatterOptions.scales.x.suggestedMax = this.getMaxAxis(xMax, xData);
        this.scatter.scatterOptions.scales.y.suggestedMin = this.getMinAxis(yMin, yData);
        this.scatter.scatterOptions.scales.y.suggestedMax = this.getMaxAxis(yMax, yData);
        let teamData = { 'x': xData, 'y': yData }
        let pointImage = new Image(imageSize, imageSize);
        pointImage.src = data["img"];
        let dataset = {
          elements: {
            point: {
              pointStyle: pointImage,
            }
          },
          data: [teamData]
        }
        this.scatter.scatterData.datasets.push(dataset);
      });
      let teamsNumberDiff = MAX_TEAM_NUMBER - teams.length;
      console.log(teams.length)
      if (teamsNumberDiff != 0) {
        for (let i = 0; i < teamsNumberDiff; i++) {
          let dataset = {
            elements: {
              point: {
                pointStyle: false,
              }
            },
            // data: [teamData]
          }
          this.scatter.scatterData.datasets.push(dataset);
        }
      }
    },
    // 引数のチーム1とチーム2でレーダーチャートのデータを取得する処理
    getRaderChart(year1, team1, year2, team2) {
      let teams = [{ "year": year1, "team": team1 }, { "year": year2, "team": team2 }];
      teams.forEach((team, i) => {
        this.rader.raderData.datasets[i].label = team["year"] + team["team"];
        this.rader.raderData.datasets[i].data = [];
        labels.forEach(async (l) => {
          let normalizedValue = await this.getNormarizeData(team["year"], team["team"], l);
          this.rader.raderData.datasets[i].data.push(normalizedValue);
          console.log(this.rader.raderData.datasets[i])
        });
      })
      this.rader.isDisplay = true;
    },
    async getNormarizeData(year, team, stats) {
      const teamDocRef = doc(db, year, team);
      const teamDocSnap = await getDoc(teamDocRef);
      const teamData = teamDocSnap.data();
      const normalizeDocRef = doc(db, "normarize", stats);
      const normalizeDocSnap = await getDoc(normalizeDocRef);
      const normalizeData = normalizeDocSnap.data();
      const min = normalizeData["min"] * MIN_RATIO;
      const max = normalizeData["max"] * MAX_RATIO;
      console.log(teamData[stats] - min)
      console.log(max - min)
      console.log((teamData[stats] - min) / (max - min))
      return ((teamData[stats] - min) / (max - min)) * 100;
    },
  }
}
</script>