<template>
  <div class="chart">
    <RadarChart :chartData="chartData" :options="options"/>
  </div>
</template>

<script>
/* eslint-disable */
// import { defineComponent } from 'vue';
import { DoughnutChart, BarChart, ScatterChart, RadarChart } from 'vue-chart-3';

// Chart.register(...registerables);

export default ({
  // export default defineComponent({
  name: 'RaderChart',
  components: { DoughnutChart, BarChart, ScatterChart, RadarChart },
  props: ['chartData', 'data', 'options']
});
</script>