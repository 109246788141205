<template>
  <div class="scatterChart">
    <ScatterChart :chartData="chartData" :options="options"/>
  </div>
</template>

<script>
  /* eslint-disable */
  // import { defineComponent } from 'vue';
  import { DoughnutChart, BarChart, ScatterChart } from 'vue-chart-3';
  import { Chart, registerables } from "chart.js";

  Chart.register(...registerables);

  export default({
  // export default defineComponent({
    name: 'ScatterChartTest',
    components: { DoughnutChart, BarChart,ScatterChart },
    props:['chartData','data','options']
  });
</script>