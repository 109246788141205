<template>
  <div class="barChart">
    <BarChart :chartData="chartData" :options="options" style="height:600px;width:auto"/>
  </div>
</template>

<script>
  /* eslint-disable */
  // import { defineComponent } from 'vue';
  import { DoughnutChart, BarChart, ScatterChart } from 'vue-chart-3';
  import { Chart, registerables } from "chart.js";

  Chart.register(...registerables);

  export default({
    name: 'BarChartTest',
    components: { DoughnutChart, BarChart,ScatterChart },
    props:['chartData','data','options'],
    created() {
    console.log(this.chartData)
  }
  });
</script>